import { BRAND_ALTERNATIVES } from './brands';

export function getFakeTitle(realTitle: string): string {
  const [brand1, brand2] = realTitle.split(' x ');
  
  // Special case for Travis Scott x McDonald's
  if (realTitle === 'Travis Scott x McDonald\'s') {
    return 'Drake x Wendy\'s';
  }
  
  const alternatives = BRAND_ALTERNATIVES[brand1];
  if (alternatives) {
    const fakeBrand = alternatives[0];
    return `${brand1} x ${fakeBrand}`;
  }
  
  return realTitle;
}