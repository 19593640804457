import React from 'react';
import { Title } from './components/Title';
import { GameHeader } from './components/GameHeader';
import { GameImage } from './components/GameImage';
import { GameOver } from './components/GameOver';
import { RevealScreen } from './components/RevealScreen';
import { GameControls } from './components/GameControls';
import { CreatorAttribution } from './components/CreatorAttribution';
import { RoundProgress } from './components/gameplay/RoundProgress';
import { StreakBonus } from './components/rewards/StreakBonus';
import { RoundStats } from './components/stats/RoundStats';
import { Position } from './types/game';
import { GAME_ROUNDS } from './data/rounds';
import { trackEvent, trackGameStart, trackAnswer, trackGameComplete, trackStreak, trackTiming } from './utils/analytics';
import { useGameHistory } from './hooks/useGameHistory';
import { useStreak } from './hooks/useStreak';
import { calculateScore } from './utils/scoring';
import { useSoundEffects } from './hooks/useSound';
import { getFakeTitle } from './utils/titleGenerator';

function App() {
  const {
    state: gameState,
    pushState,
    undo,
    redo,
    canUndo,
    canRedo
  } = useGameHistory({
    currentRound: 0,
    score: 0,
    streak: 0,
    guesses: [],
  });

  const [showReveal, setShowReveal] = React.useState(false);
  const [lastGuess, setLastGuess] = React.useState(false);
  const [selectedRound, setSelectedRound] = React.useState(GAME_ROUNDS[0]);
  const { playCorrect, playWrong, playClick } = useSoundEffects();
  const { multiplier, showBonus } = useStreak(gameState.streak);
  const startTime = React.useRef(Date.now());

  // Track game start on mount
  React.useEffect(() => {
    trackGameStart();
    return () => {
      // Track early exits
      if (gameState.currentRound < GAME_ROUNDS.length) {
        trackEvent({
          action: 'exit_game',
          category: 'Game',
          label: `Round: ${gameState.currentRound + 1}`,
          value: gameState.score
        });
      }
    };
  }, []);

  React.useEffect(() => {
    setSelectedRound(GAME_ROUNDS[gameState.currentRound]);
  }, [gameState.currentRound]);

  const handleGuess = (position: Position) => {
    if (showReveal) return;
    
    const timeSpent = Date.now() - startTime.current;
    playClick();
    const isCorrect = position === selectedRound.correctPosition;
    const newGuesses = [...gameState.guesses, isCorrect];
    const newStreak = isCorrect ? gameState.streak + 1 : 0;
    
    trackAnswer(selectedRound.id, isCorrect, timeSpent);
    
    if (isCorrect) {
      playCorrect();
      if (gameState.streak + 1 >= 3) {
        trackStreak(gameState.streak + 1);
      }
    } else {
      playWrong();
    }

    setLastGuess(isCorrect);
    pushState({
      ...gameState,
      guesses: newGuesses,
      streak: newStreak,
      score: calculateScore(newGuesses, newStreak),
    });
    setShowReveal(true);
  };

  const handleContinue = () => {
    setShowReveal(false);
    startTime.current = Date.now(); // Reset timer for next question
    pushState({
      ...gameState,
      currentRound: gameState.currentRound + 1,
    });
  };

  const handleRestart = () => {
    trackEvent({
      action: 'restart_game',
      category: 'Game',
      label: 'Manual Restart'
    });
    window.location.reload();
  };

  if (gameState.currentRound >= GAME_ROUNDS.length) {
    const correctGuesses = gameState.guesses.filter(Boolean).length;
    const gameTime = Date.now() - startTime.current;
    
    trackTiming('Game', 'completion_time', gameTime);
    trackGameComplete(gameState.score, correctGuesses);
    
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
        <GameOver score={gameState.score} onRestart={handleRestart} />
        <CreatorAttribution />
        <GameControls
          onUndo={undo}
          onRedo={redo}
          canUndo={canUndo}
          canRedo={canRedo}
        />
      </div>
    );
  }

  const leftImage = selectedRound.correctPosition === 'left' ? selectedRound.realImage : selectedRound.fakeImage;
  const rightImage = selectedRound.correctPosition === 'left' ? selectedRound.fakeImage : selectedRound.realImage;
  const leftTitle = selectedRound.correctPosition === 'left' ? selectedRound.title : getFakeTitle(selectedRound.title);
  const rightTitle = selectedRound.correctPosition === 'left' ? getFakeTitle(selectedRound.title) : selectedRound.title;

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 p-2 md:p-4">
      <div className="mx-auto max-w-6xl">
        <Title />
        <GameHeader 
          score={gameState.score} 
          round={gameState.currentRound + 1} 
          totalRounds={GAME_ROUNDS.length}
          streak={gameState.streak}
        />
        
        <RoundProgress
          currentRound={gameState.currentRound + 1}
          totalRounds={GAME_ROUNDS.length}
          guesses={gameState.guesses}
        />

        <div className="mt-2 md:mt-4 grid grid-cols-2 gap-2 md:gap-8 relative">
          <GameImage 
            imageUrl={leftImage}
            onClick={() => handleGuess('left')}
            disabled={showReveal}
            title={leftTitle}
          />
          <GameImage 
            imageUrl={rightImage}
            onClick={() => handleGuess('right')}
            disabled={showReveal}
            title={rightTitle}
          />
          {showBonus && <StreakBonus streak={gameState.streak} multiplier={multiplier} />}
        </div>

        {showReveal && (
          <>
            <RevealScreen
              round={selectedRound}
              isCorrect={lastGuess}
              onContinue={handleContinue}
            />
            <RoundStats correctPercentage={75} />
          </>
        )}

        <CreatorAttribution />
        <GameControls
          onUndo={undo}
          onRedo={redo}
          canUndo={canUndo}
          canRedo={canRedo}
        />
      </div>
    </div>
  );
}

export default App;