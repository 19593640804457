import React, { useState } from 'react';
import { Sparkles, HelpCircle } from 'lucide-react';
import { HowToPlay } from './HowToPlay';

export function Title() {
  const [showHowToPlay, setShowHowToPlay] = useState(false);

  return (
    <div className="text-center mb-4 md:mb-8 px-2">
      <div className="flex items-center justify-center gap-1 md:gap-3 mb-1 md:mb-2">
        <span className="text-lg md:text-2xl">⚡️</span>
        <h1 className="text-xl md:text-4xl font-[700] text-gray-900 font-cal tracking-tight">
          Real or Fake: Brand Collabs
        </h1>
        <span className="text-lg md:text-2xl">⚡️</span>
      </div>
      <div className="flex items-center justify-center gap-2">
        <p className="text-xs md:text-base text-gray-600">Can you spot the real brand collaborations?</p>
        <button
          onClick={() => setShowHowToPlay(true)}
          className="inline-flex items-center justify-center text-blue-600 hover:text-blue-700 transition-colors"
          aria-label="How to play"
        >
          <HelpCircle className="h-4 w-4 md:h-5 md:w-5" />
        </button>
      </div>
      <HowToPlay isOpen={showHowToPlay} onClose={() => setShowHowToPlay(false)} />
    </div>
  );
}