import { useState, useEffect } from 'react';
import { STREAK_MULTIPLIERS } from '../constants/game';

export function useStreak(streak: number) {
  const [multiplier, setMultiplier] = useState(1);
  const [showBonus, setShowBonus] = useState(false);

  useEffect(() => {
    for (const [threshold, mult] of Object.entries(STREAK_MULTIPLIERS)) {
      if (streak >= Number(threshold)) {
        setMultiplier(mult);
        setShowBonus(true);
        const timer = setTimeout(() => setShowBonus(false), 2000);
        return () => clearTimeout(timer);
      }
    }
  }, [streak]);

  return { multiplier, showBonus };
}