import { useEffect, useCallback } from 'react';
import useSound from 'use-sound';

const correctSound = '/sounds/correct.mp3';
const wrongSound = '/sounds/wrong.mp3';
const clickSound = '/sounds/click.mp3';

export function useSoundEffects() {
  const [playCorrect] = useSound(correctSound);
  const [playWrong] = useSound(wrongSound);
  const [playClick] = useSound(clickSound);

  return {
    playCorrect,
    playWrong,
    playClick
  };
}