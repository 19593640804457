import { Round } from '../types/game';

const ROUNDS: Round[] = [
  {
    id: 6,
    title: 'IKEA x LEGO',
    realImage: 'https://i.imgur.com/8GPKo8N.png',
    fakeImage: 'https://i.imgur.com/zXQz9nE.png',
    revealImage: 'https://i.imgur.com/dRWWeEW.png',
    fact: 'The storage boxes had to meet both IKEA\'s sustainability requirements and LEGO\'s precise measurement standards - took 18 prototypes to perfect',
    correctPosition: 'right'
  },
  {
    id: 1,
    title: "Nike x Ben & Jerry's",
    realImage: 'https://i.imgur.com/k8LKvFK.png',
    fakeImage: 'https://i.imgur.com/O9yXd3N.png',
    revealImage: 'https://i.imgur.com/U8svf9W.jpeg',
    fact: 'This 2020 collab caused Nike to change their bot protection after resellers used 1,500 devices to buy entire stocks in seconds',
    correctPosition: Math.random() < 0.5 ? 'left' : 'right'
  },
  {
    id: 2,
    title: 'LEGO x Levi\'s',
    realImage: 'https://i.imgur.com/ewOIwOk.png',
    fakeImage: 'https://i.imgur.com/kJoGwmt.png',
    revealImage: 'https://i.imgur.com/ZzZVLRF.jpeg',
    fact: 'The collection took 2 years to develop because they had to invent a new way to permanently bond LEGO elements to fabric that could survive washing',
    correctPosition: Math.random() < 0.5 ? 'left' : 'right'
  },
  {
    id: 3,
    title: 'KFC x Crocs',
    realImage: 'https://i.imgur.com/fGEFSrs.png',
    fakeImage: 'https://i.imgur.com/IxE8DQs.png',
    revealImage: 'https://i.imgur.com/spgOVUr.jpeg',
    fact: 'This collab led to Crocs\' highest-ever trading day on NYSE, with stock jumping 11% in 24 hours',
    correctPosition: Math.random() < 0.5 ? 'left' : 'right'
  },
  {
    id: 4,
    title: 'Xbox x Taco Bell',
    realImage: 'https://i.imgur.com/8V8hDF4.png',
    fakeImage: 'https://i.imgur.com/fIBbYbW.png',
    revealImage: 'https://i.imgur.com/L4ktoaN.jpeg',
    fact: 'The promotion generated $16M in additional revenue for Taco Bell while costing Microsoft less than $1M in consoles',
    correctPosition: Math.random() < 0.5 ? 'left' : 'right'
  },
  {
    id: 5,
    title: 'Pokémon x Oreo',
    realImage: 'https://i.imgur.com/lFDhYP7.png',
    fakeImage: 'https://i.imgur.com/65wICMq.png',
    revealImage: 'https://i.imgur.com/yFY74Rk.jpeg',
    fact: 'Oreo had to develop new printing technology for this collab - it was the first time they could emboss 16 different designs simultaneously',
    correctPosition: Math.random() < 0.5 ? 'left' : 'right'
  },
  {
    id: 7,
    title: 'Travis Scott x McDonald\'s',
    realImage: 'https://i.imgur.com/5sTwtgg.png',
    fakeImage: 'https://i.imgur.com/N7Aq24E.png',
    revealImage: 'https://i.imgur.com/zB82hZH.png',
    fact: 'McDonald\'s had to change their supply chain network after 50 million customers ordered the meal in first month - causing lettuce shortages in 3 states',
    correctPosition: Math.random() < 0.5 ? 'left' : 'right'
  },
  {
    id: 8,
    title: 'Palace x Mercedes-AMG',
    realImage: 'https://i.imgur.com/gg2ZHFQ.png',
    fakeImage: 'https://i.imgur.com/3WZGOBr.png',
    revealImage: 'https://i.imgur.com/uslZIjo.png',
    fact: 'First time Mercedes allowed their logo to be modified - legal negotiations took 8 months longer than the actual product development',
    correctPosition: Math.random() < 0.5 ? 'left' : 'right'
  },
  {
    id: 9,
    title: 'Supreme x Spalding',
    realImage: 'https://i.imgur.com/mstXAsf.png',
    fakeImage: 'https://i.imgur.com/rhFBpRm.png',
    revealImage: 'https://i.imgur.com/JaNKco0.jpeg',
    fact: 'Supreme bought production rights rather than licensing - they still manufacture these basketballs, making it their longest-running continuous product',
    correctPosition: Math.random() < 0.5 ? 'left' : 'right'
  },
  {
    id: 10,
    title: 'Balenciaga x Fortnite',
    realImage: 'https://i.imgur.com/xrUSyYw.png',
    fakeImage: 'https://i.imgur.com/beAQ1vI.png',
    revealImage: 'https://i.imgur.com/thRtArp.png',
    fact: 'Generated $108M in digital sales, proving luxury brands could make more from virtual clothes than physical - changed fashion\'s whole digital strategy',
    correctPosition: Math.random() < 0.5 ? 'left' : 'right'
  }
];

// Export rounds without shuffling to maintain IKEA x LEGO as first round
export const GAME_ROUNDS = ROUNDS;