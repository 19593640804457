export const BRAND_ALTERNATIVES: Record<string, string[]> = {
  'Nike': ['Heinz', 'Campbell\'s', 'Pepsi'],
  'LEGO': ['Nike', 'Adidas', 'Puma'],
  'KFC': ['Doritos', 'Pringles', 'Lay\'s'],
  'Xbox': ['Subway', 'McDonald\'s', 'Burger King'],
  'Pokémon': ['Pringles', 'Doritos', 'Cheetos'],
  'IKEA': ['Nintendo', 'PlayStation', 'Xbox'],
  'Travis Scott': ['Drake', 'Kanye West', 'Post Malone'],
  'Palace': ['Toyota', 'BMW', 'Audi'],
  'Supreme': ['Wilson', 'Nike', 'Adidas'],
  'Balenciaga': ['Roblox', 'Minecraft', 'PUBG']
};