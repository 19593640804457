import React from 'react';
import { Trophy, Flame } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import CountUp from 'react-countup';

interface GameHeaderProps {
  score: number;
  round: number;
  totalRounds: number;
  streak: number;
}

export function GameHeader({ score, round, totalRounds, streak }: GameHeaderProps) {
  return (
    <div className="bg-white rounded-lg p-2 md:p-6 shadow-lg grid grid-cols-3 items-center">
      <div className="flex items-center gap-1 md:gap-3">
        <motion.div
          animate={{ 
            rotate: score > 0 ? [0, -20, 20, -20, 20, 0] : 0,
            scale: score > 0 ? [1, 1.2, 1] : 1
          }}
          transition={{ duration: 0.5 }}
        >
          <Trophy className="h-4 w-4 md:h-6 md:w-6 text-yellow-500 drop-shadow-lg" />
        </motion.div>
        <div>
          <span className="text-xs md:text-sm text-gray-600 block">Score</span>
          <CountUp
            end={score}
            className="text-sm md:text-2xl font-bold bg-gradient-to-r from-yellow-500 to-yellow-600 bg-clip-text text-transparent"
            duration={1}
          />
        </div>
      </div>

      <motion.div 
        className="text-center"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        key={round}
      >
        <span className="text-xs md:text-sm text-gray-600 block">Round</span>
        <p className="text-sm md:text-xl font-bold">{round}/{totalRounds}</p>
      </motion.div>

      <div className="flex items-center gap-1 md:gap-3 justify-end">
        <div className="text-right">
          <span className="text-xs md:text-sm text-gray-600 block">Streak</span>
          <CountUp
            end={streak}
            className="text-sm md:text-xl font-bold"
            duration={0.5}
          />
        </div>
        <AnimatePresence>
          {streak > 0 && (
            <motion.div
              initial={{ scale: 0 }}
              animate={{ 
                scale: [1, 1.2, 1],
                rotate: [0, -10, 10, -10, 0]
              }}
              exit={{ scale: 0 }}
              transition={{ 
                duration: 0.5,
                repeat: Infinity,
                repeatType: "reverse"
              }}
            >
              <Flame className="h-4 w-4 md:h-6 md:w-6 text-orange-500 drop-shadow-lg" />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}