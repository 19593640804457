import React from 'react';
import { RotateCcw } from 'lucide-react';
import { motion } from 'framer-motion';
import { ShareScreen } from './ShareScreen';

interface GameOverProps {
  score: number;
  onRestart: () => void;
}

export function GameOver({ score, onRestart }: GameOverProps) {
  return (
    <div className="min-h-screen flex items-center justify-center p-4">
      <ShareScreen 
        score={score} 
        totalRounds={10} 
        bestStreak={0}
      />
      
      <motion.button
        onClick={onRestart}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        className="fixed bottom-4 left-1/2 -translate-x-1/2 bg-white text-gray-900 py-3 px-6 rounded-xl flex items-center gap-2 shadow-lg hover:shadow-xl transition-shadow"
      >
        <RotateCcw className="h-5 w-5" />
        <span className="font-medium">Play Again</span>
      </motion.button>
    </div>
  );
}