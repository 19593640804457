import React from 'react';
import { Undo2, Redo2 } from 'lucide-react';

interface GameControlsProps {
  onUndo: () => void;
  onRedo: () => void;
  canUndo: boolean;
  canRedo: boolean;
}

export function GameControls({ onUndo, onRedo, canUndo, canRedo }: GameControlsProps) {
  return (
    <div className="fixed bottom-2 right-2 md:bottom-4 md:right-4 flex gap-2">
      <button
        onClick={onUndo}
        disabled={!canUndo}
        className="p-2 rounded-full bg-white shadow-lg hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
        title="Undo"
      >
        <Undo2 className="h-4 w-4 md:h-5 md:w-5 text-gray-700" />
      </button>
      <button
        onClick={onRedo}
        disabled={!canRedo}
        className="p-2 rounded-full bg-white shadow-lg hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
        title="Redo"
      >
        <Redo2 className="h-4 w-4 md:h-5 md:w-5 text-gray-700" />
      </button>
    </div>
  );
}