import React from 'react';
import { motion } from 'framer-motion';
import { TrendingUp } from 'lucide-react';

interface RoundStatsProps {
  correctPercentage: number;
}

export function RoundStats({ correctPercentage }: RoundStatsProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="text-center mt-4"
    >
      <div className="inline-flex items-center gap-2 bg-white/90 backdrop-blur-sm rounded-full px-4 py-2 shadow-sm">
        <TrendingUp className="h-4 w-4 text-blue-500" />
        <span className="text-sm text-gray-600">
          <span className="font-bold text-gray-900">{correctPercentage}%</span> of players got this right
        </span>
      </div>
    </motion.div>
  );
}