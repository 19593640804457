import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Camera, Copy, Instagram, MessageCircle } from 'lucide-react';
import { generateShareText, shareToClipboard, shareToSMS, shareToSocial } from '../utils/sharing';

interface ShareScreenProps {
  score: number;
  totalRounds: number;
  bestStreak: number;
}

export function ShareScreen({ score, totalRounds, bestStreak }: ShareScreenProps) {
  const [copied, setCopied] = React.useState(false);
  const shareMessage = generateShareText(score, totalRounds);
  
  const handleCopy = async () => {
    await shareToClipboard(shareMessage);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleSocialShare = async (platform: 'instagram' | 'snapchat') => {
    await shareToClipboard(shareMessage);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleMessage = () => {
    if (navigator.share) {
      shareToSocial(shareMessage);
    } else {
      shareToSMS(shareMessage);
    }
  };

  const getMessage = () => {
    if (score >= 800) return "You're a collab expert! 🏆";
    if (score >= 500) return "Not bad! Share & challenge friends 🎯";
    return "Practice makes perfect! Try again 💪";
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm"
    >
      <div className="w-full max-w-md bg-white rounded-2xl shadow-xl overflow-hidden">
        <div className="p-6 text-center">
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ type: "spring", duration: 0.6 }}
            className="mb-4"
          >
            <span className="text-[72px] font-cal font-bold leading-none bg-gradient-to-br from-blue-600 to-purple-600 bg-clip-text text-transparent">
              {score}
            </span>
          </motion.div>
          <motion.p 
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="text-gray-600 font-space mb-8"
          >
            {getMessage()}
          </motion.p>

          <div className="grid grid-cols-2 gap-3">
            <button
              onClick={() => handleSocialShare('instagram')}
              className="flex items-center justify-center gap-2 bg-gradient-to-br from-purple-600 to-pink-500 text-white p-4 rounded-xl font-medium hover:opacity-90 transition-opacity"
            >
              <Instagram className="h-5 w-5" />
              <span>Instagram Story</span>
            </button>
            
            <button
              onClick={() => handleSocialShare('snapchat')}
              className="flex items-center justify-center gap-2 bg-[#FFFC00] text-black p-4 rounded-xl font-medium hover:opacity-90 transition-opacity"
            >
              <Camera className="h-5 w-5" />
              <span>Snapchat</span>
            </button>

            <button
              onClick={handleMessage}
              className="flex items-center justify-center gap-2 bg-[#007AFF] text-white p-4 rounded-xl font-medium hover:opacity-90 transition-opacity"
            >
              <MessageCircle className="h-5 w-5" />
              <span>iMessage</span>
            </button>

            <button
              onClick={handleCopy}
              className="flex items-center justify-center gap-2 bg-gray-100 text-gray-900 p-4 rounded-xl font-medium hover:bg-gray-200 transition-colors relative"
            >
              <Copy className="h-5 w-5" />
              <span>Copy Link</span>
              <AnimatePresence>
                {copied && (
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    className="absolute -top-8 left-1/2 -translate-x-1/2 bg-black text-white text-xs px-2 py-1 rounded"
                  >
                    Copied!
                  </motion.div>
                )}
              </AnimatePresence>
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
}