// Analytics configuration
const isProd = window.location.hostname === 'brandtrivia.io';
const SESSION_STORAGE_KEY = 'game_session';

function logAnalytics(type: string, data: Record<string, any>): void {
  if (!isProd) {
    console.log(`[Analytics - ${type}]`, data);
  }
}

function initSession(): void {
  if (!sessionStorage.getItem(SESSION_STORAGE_KEY)) {
    sessionStorage.setItem(SESSION_STORAGE_KEY, Date.now().toString());
  }
}

// Google Analytics Event Types
export type AnalyticsEvent = {
  action: string;
  category: string;
  label?: string;
  value?: number;
  metric?: number;
};

// Track timing events
export function trackTiming(category: string, variable: string, value: number): void {
  const data = {
    event_category: category,
    name: variable,
    value: Math.round(value),
  };

  logAnalytics('Timing', data);

  if (typeof window.gtag !== 'undefined' && isProd) {
    window.gtag('event', 'timing_complete', data);
  }
}

// Track custom events
export function trackEvent({ action, category, label, value }: AnalyticsEvent): void {
  const data = {
    event_category: category,
    event_label: label,
    value: value,
  };

  logAnalytics('Event', { action, ...data });

  if (typeof window.gtag !== 'undefined' && isProd) {
    window.gtag('event', action, data);
  }
}

// Track game start
export function trackGameStart(): void {
  initSession();
  logAnalytics('Game Start', { timestamp: Date.now() });

  // Reset session data
  if (typeof window.gtag !== 'undefined' && isProd) {
    window.gtag('config', 'G-VRBQD5T3H1', {
      page_title: 'Game Session',
      page_location: window.location.origin + '/game',
      page_path: '/game'
    });
  }

  trackEvent({
    action: 'start_game',
    category: 'Game',
    label: 'New Game Started'
  });
}

// Track question answer
export function trackAnswer(roundId: number, isCorrect: boolean, timeSpent: number): void {
  logAnalytics('Question Answer', { roundId, isCorrect, timeSpent });

  trackEvent({
    action: 'answer_question',
    category: 'Game',
    label: `Round ${roundId} - ${isCorrect ? 'Correct' : 'Incorrect'}`,
    value: timeSpent,
  });

  // Track answer accuracy separately
  trackEvent({
    action: 'answer_accuracy',
    category: 'Performance',
    label: `Round ${roundId}`,
    value: isCorrect ? 1 : 0,
  });
}

// Track game completion
export function trackGameComplete(score: number, correctGuesses: number): void {
  const averageScore = score / correctGuesses;
  const isPerfectGame = correctGuesses === 10;
  const sessionDuration = Date.now() - Number(sessionStorage.getItem(SESSION_STORAGE_KEY));

  logAnalytics('Game Complete', { 
    score, 
    correctGuesses, 
    averageScore,
    isPerfectGame,
    sessionDuration
  });

  trackEvent({
    action: 'complete_game',
    category: 'Game',
    label: `Score: ${score}`,
    value: score,
    metric: averageScore,
  });
  
  // Track session duration
  trackTiming('Session', 'total_duration', sessionDuration);
  
  trackEvent({
    action: 'correct_guesses',
    category: 'Game',
    label: `Correct: ${correctGuesses}/10`,
    value: correctGuesses,
  });
  
  if (isPerfectGame) {
    trackEvent({
      action: 'achievement_unlocked',
      category: 'Achievement',
      label: 'perfect_game',
      value: score
    });
  }
}

// Track streak achievements
export function trackStreak(streak: number): void {
  logAnalytics('Streak', { streak });

  trackEvent({
    action: 'achieve_streak',
    category: 'Performance',
    label: `Streak: ${streak}`,
    value: streak,
  });
  
  if (streak >= 5) {
    trackEvent({
      action: 'achievement_unlocked',
      category: 'Achievement',
      label: `${streak}x_streak`,
      value: streak,
    });
  }
}

// Track share actions
export function trackShare(platform: string): void {
  logAnalytics('Share', { platform });
  
  trackEvent({
    action: 'share',
    category: 'Social',
    label: platform,
  });
}