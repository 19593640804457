type ShareMessage = {
  text: string;
  url: string;
};

const SHARE_URL = 'https://brandtrivia.io';

export function generateShareText(score: number, totalRounds: number): ShareMessage {
  const getEmoji = (score: number) => {
    if (score >= 800) return '🏆';
    if (score >= 500) return '🎯';
    return '💪';
  };

  const text = `Think you know brands? Just scored ${score} points spotting fake collabs ${getEmoji(score)}`;
  
  return {
    text,
    url: SHARE_URL
  };
}

export function generateInstagramStory(score: number): string {
  return `${score}\nThink you can spot fake collabs better than me?`;
}

export async function shareToClipboard(message: ShareMessage): Promise<void> {
  const fullText = `${message.text} Try to beat my score: ${message.url}`;
  await navigator.clipboard.writeText(fullText);
}

export async function shareToSocial(message: ShareMessage): Promise<void> {
  if (navigator.share) {
    await navigator.share({
      text: message.text,
      url: message.url
    });
  } else {
    await shareToClipboard(message);
  }
}

export function shareToSMS(message: ShareMessage): void {
  const fullText = `${message.text} Try to beat my score: ${message.url}`;
  const smsUrl = `sms:&body=${encodeURIComponent(fullText)}`;
  window.location.href = smsUrl;
}