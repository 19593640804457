import React from 'react';
import { HelpCircle, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

interface HowToPlayProps {
  isOpen: boolean;
  onClose: () => void;
}

export function HowToPlay({ isOpen, onClose }: HowToPlayProps) {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 p-4"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-white rounded-lg p-8 shadow-xl max-w-lg w-full relative"
          >
            <button
              onClick={onClose}
              className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
            >
              <X className="h-6 w-6" />
            </button>

            <div className="flex items-center gap-3 mb-8">
              <HelpCircle className="h-8 w-8 text-blue-500" />
              <h2 className="text-2xl font-bold">How to Play</h2>
            </div>

            <div className="space-y-8">
              <div className="text-center">
                <h3 className="text-xl font-semibold mb-2">Goal</h3>
                <p className="text-gray-600">
                  Identify which brand collaboration is real and which is fake.
                </p>
              </div>

              <div className="text-center">
                <h3 className="text-xl font-semibold mb-4">Scoring</h3>
                <p className="text-gray-600 mb-2">100 points for each correct answer</p>
                <div className="mb-2">
                  <p className="text-gray-600 mb-1">Streak bonuses:</p>
                  <p className="text-gray-600">3 correct: 2x multiplier</p>
                  <p className="text-gray-600">5 correct: 3x multiplier</p>
                  <p className="text-gray-600">7 correct: 4x multiplier</p>
                </div>
                <p className="text-gray-600">Perfect game bonus: 1000 points</p>
              </div>

              <div className="text-center">
                <h3 className="text-xl font-semibold mb-4">How to win</h3>
                <p className="text-gray-600 mb-2">Click on the image you think is the real collaboration</p>
                <p className="text-gray-600 mb-2">Learn interesting facts about real collaborations</p>
                <p className="text-gray-600 mb-2">Try to maintain your streak for bonus points</p>
                <p className="text-gray-600">Aim for a perfect score across all 10 rounds</p>
              </div>
            </div>

            <button
              onClick={onClose}
              className="w-full mt-8 bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 transition-colors font-semibold text-lg"
            >
              Got it!
            </button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}