import React from 'react';
import { motion } from 'framer-motion';

interface RoundProgressProps {
  currentRound: number;
  totalRounds: number;
  guesses: boolean[];
}

export function RoundProgress({ currentRound, totalRounds, guesses }: RoundProgressProps) {
  return (
    <div className="w-full bg-gray-100 rounded-full h-1.5 mb-4 overflow-hidden">
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: `${(currentRound / totalRounds) * 100}%` }}
        className="h-full bg-gradient-to-r from-blue-500 to-blue-600"
      />
      <div className="flex gap-0.5 mt-1">
        {guesses.map((isCorrect, index) => (
          <motion.div
            key={index}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            className={`h-1 flex-1 rounded-full ${
              isCorrect ? 'bg-green-500' : 'bg-red-500'
            }`}
          />
        ))}
      </div>
    </div>
  );
}