import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Sparkles } from 'lucide-react';

interface StreakBonusProps {
  streak: number;
  multiplier: number;
}

export function StreakBonus({ streak, multiplier }: StreakBonusProps) {
  return (
    <AnimatePresence>
      {streak >= 3 && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="absolute top-0 right-0 -translate-y-full p-2"
        >
          <div className="flex items-center gap-2 bg-white/90 backdrop-blur-sm rounded-full px-3 py-1 shadow-lg">
            <Sparkles className="h-4 w-4 text-yellow-500" />
            <span className="text-sm font-bold text-gray-800">{multiplier}x Bonus!</span>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}