import React from 'react';
import { motion } from 'framer-motion';

interface GameImageProps {
  imageUrl: string;
  onClick: () => void;
  disabled?: boolean;
  title: string;
}

export function GameImage({ imageUrl, onClick, disabled, title }: GameImageProps) {
  const [isLoading, setIsLoading] = React.useState(true);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="relative"
    >
      <h2 className="text-xs md:text-lg font-bold text-center text-gray-700 mb-1 md:mb-2 font-space px-1">{title}</h2>
      <motion.button
        onClick={onClick}
        disabled={disabled}
        whileHover={disabled ? {} : { scale: 1.02 }}
        whileTap={disabled ? {} : { scale: 0.98 }}
        className="group relative w-full aspect-[3/4] md:aspect-square overflow-hidden rounded-lg md:rounded-xl transition-all hover:shadow-2xl focus:outline-none focus:ring-4 focus:ring-blue-500 disabled:opacity-75 disabled:cursor-not-allowed bg-white"
      >
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
            <div className="w-6 h-6 md:w-8 md:h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
          </div>
        )}
        <motion.div
          className="absolute inset-0 bg-gradient-to-tr from-blue-500/10 to-purple-500/10 opacity-0 group-hover:opacity-100 transition-opacity"
        />
        <motion.img 
          src={imageUrl}
          alt="Brand collaboration"
          className="h-full w-full object-contain p-1 md:p-2"
          initial={{ scale: 1.1 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
          onLoad={() => setIsLoading(false)}
        />
      </motion.button>
    </motion.div>
  );
}