import React from 'react';
import { motion } from 'framer-motion';
import { TiktokIcon } from './TiktokIcon';

export function CreatorAttribution() {
  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.5 }}
      className="text-center mt-8 mb-4"
    >
      <a
        href="https://www.tiktok.com/@mobibojoe"
        target="_blank"
        rel="noopener noreferrer"
        className="inline-flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors text-sm"
      >
        <span>Created by @mobibojoe</span>
        <TiktokIcon className="h-4 w-4" />
      </a>
    </motion.div>
  );
}