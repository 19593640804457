import { STREAK_MULTIPLIERS, BASE_SCORE, PERFECT_GAME_BONUS } from '../constants/game';

export function calculateScore(guesses: boolean[], currentStreak: number): number {
  const baseScore = guesses.filter(Boolean).length * BASE_SCORE;
  
  let multiplier = 1;
  for (const [streak, mult] of Object.entries(STREAK_MULTIPLIERS)) {
    if (currentStreak >= Number(streak)) {
      multiplier = mult;
    }
  }

  const streakBonus = baseScore * (multiplier - 1);
  const perfectGameBonus = guesses.length === 10 && guesses.every(Boolean) ? PERFECT_GAME_BONUS : 0;

  return baseScore + streakBonus + perfectGameBonus;
}